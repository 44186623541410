import { useKeycloak } from '@react-keycloak/web'
import { UIConstants } from '../components/Utils'
import { Utils as FUtils } from '../components/Utils'
import { toast } from 'react-toastify'

import translations from './translations'

function fetchWithTimeout(url, options, timeout) {
	const timeoutPromise = new Promise((_, reject) => {
		setTimeout(() => {
			reject(new Error('Request timed out'))
		}, timeout)
	})

	const fetchPromise = fetch(url, options)

	// Use Promise.race to wait for the fetch or the timeout
	return Promise.race([fetchPromise, timeoutPromise])
}

export default function Utils() {
	const { keycloak, initialized } = useKeycloak()

	const locale = FUtils.getLocale(keycloak)

	const lang = (id) => {
		return translations[locale][id]
	}

	return {
		call: async ({ cb = () => {}, cbError = () => {}, url, type, body, file = false, binaryStream = false, timeout = 0 }) => {
			// STRAPI headers should be without content-type or else it will fail to fetch
			let headers

			if (file) {
				headers = {
					Accept: 'application/json, text/plain, */*',
					Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
				}
			} else if (binaryStream) {
				headers = {
					Accept: 'text/csv', // Use 'text/csv' for file downloads
					Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
				}
			} else {
				headers = {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json',
					Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
				}
			}

			try {
				let response

				if (timeout > 0) {
					response = await fetchWithTimeout(
						`${url}`,
						{
							method: type,
							headers,
							body
						},
						timeout
					)
				} else {
					response = await fetch(`${url}`, {
						method: type,
						headers,
						body
					})
				}

				let message = ''
				if (response.status === 204) {
					return cb()
				}
				if(binaryStream) {
					if(response.ok) {
						const blob = await response.blob()
						cb(blob)
					}else{
						cbError({
							status: response.status,
							message: response.message
						})
					}
				}else{
					let responseBody = ''
					try {
						responseBody = await response.json()
					} catch (e) {
						responseBody = ''
					}
					if (response.status === 401) {
						message = `${lang('401')}`
						//toast.error(message, { toastId: message })
						if (responseBody.invalidToken) keycloak.logout()
					}

					if (!response.ok) {
						//toast.error(responseBody.message, { toastId: responseBody.message })
						cbError({
							status: response.status,
							message: responseBody.message
						})
					} else {
						responseBody.data ? cb(responseBody.data) : cb(responseBody)
					}
					if (response.status === 500) {
						message = `${lang('500')} ${lang('on-it')}`
						return toast.error(message, { toastId: message })
					}
				}

				
			} catch (error) {
				toast.error(error.message, { toastId: error.message })
				console.error(error)
			}
		},
		getFilter: (params) => {
			let filter = '?'
			for (const [key, value] of Object.entries(params)) {
				if (value !== UIConstants.ALL && value !== undefined) {
					filter = filter + `${filter === '?' ? '' : '&'}${key}=${Array.isArray(value) ? JSON.stringify(value) : value}`
				}
			}
			return filter === '?' ? '' : filter
		}
	}
}
